/*
profile buttons in mydashboard
*/

.profile-btn{
    margin-right: 0px;
  }

  .pb1{
      margin-right: 0px;
  }
  


  @media screen and (min-width: 350px){
    .pb1{
      margin-right: 10px;
    }
  }

  @media screen and (min-width: 455px){
    .pb2{
      margin-right: 10px;
    }
  }

  @media screen and (min-width: 560px){
    .profile-btn{
      margin-right: 10px;
    }
  }
  


  .invite-btn{
    width: 300px;
  }

