.body {
  min-height: calc(100vh - 50px);
  justify-content: center;
  align-items: center;
  display: flex;
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
}

.word-card {
  -webkit-box-shadow: -1px -7px 15px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: -1px -7px 15px -2px rgba(0,0,0,0.75);
  box-shadow: -1px -7px 15px -2px rgba(0,0,0,0.75);
}


.box {
  display: inline-block;
  background: #fff;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;
  margin: 20px;
  -webkit-box-shadow: -1px -7px 15px -2px rgba(0,0,0,0.75);
  -moz-box-shadow: -1px -7px 15px -2px rgba(0,0,0,0.75);
  box-shadow: -1px -7px 15px -2px rgba(0,0,0,0.75);
  border-radius: 5%;
}

.react-resizable {
  position: relative;
}

.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
}

.dv-star-rating-star i {
  font-size: 28px !important;
  padding: 3px;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
  display: none;
  outline: none !important;
}
input {
  outline: none !important;
}

*:disabled {
  background: #777 !important;
}
.custom-file-input::before {
  outline: none !important;
  content: 'SELECT IMAGE';
  display: block;
  background: rgb(46, 149, 198);
  border: none;
  border-radius: 3px;
  padding: 10px 15px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: 700;
  font-size: 10pt;
  margin: 10px;
  margin-left: 45px;
  width: 95px;
}
.custom-file-input:hover::before {
  border-color: black;
  outline: none !important;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  outline: none !important;
}


/*
share buttons
*/

.Demo__some-network {
  vertical-align: top;
  display: inline-block;
  margin-right: 15px;
  text-align: center;
}

.Demo__some-network__share-button {
  cursor: pointer;
  float: left;
}

.Demo__some-network__share-button:hover:not(:active) {
  opacity: 0.75;
}



/* The container */
.container2 {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  margin-left: 0;
  cursor: pointer;
  font-size: 15px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container2 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container2:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container2 input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container2 input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container2 .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* * {
  box-sizing: border-box;
} */

.row_59 {
  display: flex;
  flex-direction: row;
}

/* Create two equal columns that sits next to each other */
.column_1 {
  flex: 1;
  padding: 10px;
}
.column_2{
  flex:1;
  padding: 10px;
}

@media screen and (max-width: 585px){
  .row_59{
    flex-direction: column;
  }
}

#drops:hover{
  background-color: #2196F3;
  padding-left: 30px;
}




/*
Admin dashboard
*/

.top-items-admin{
  display: block;
}

.graphs-admin{
  display: block;
  padding-top: 200px;
}

.Comments{
  display: block;
}

.activityList{
  display: block;
}

.graph1-paper{
  float:left;
  margin-left: 50px;
  margin-bottom: 20px;
}

.row-two{
  display: block;
width: 100%;
height: 600px;  
padding-top: 500px;

}

@media (min-width: 300px){
  .graph1-paper-1{
    width: 100%;
  }

  .Comments{
    margin-left: 10%;
  }

  .activityList{
   margin-left: 10%; 
  }
}


@media (min-width: 730px){
  .graph1-paper{
    margin-left:10%;
    margin-bottom:20px;
    
  }

  .Comments{
    margin-left: 10%;
  }

  .activityList{
    margin-left: 10%;
  }


  .graph1-paper-1{
    width: 80%;
  }
}

@media (min-width: 800px){

  .graph1-paper{
    margin-left:14%;
    margin-bottom:20px;
  }

  
  .Comments{
    margin-left: 14%;
  }
 
  .activityList{
    margin-left: 14%;
  }

}


@media (min-width: 1200px){
 
  .graph1-paper{  
    float: left;
    margin-bottom:0px;
    margin-left: 20px;
  }

  
  .Comments{
    margin-left: 20px;
    float: left;
  }

  .activityList{
    margin-left: 20px;
    float: left;
  }



}


.manage-users{
  width: 100%;
  height: 1400px;
  display: inline-block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
}